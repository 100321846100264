import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, interval, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { UserAttributesInterface } from '../Auth/bs-auth.service';
import { BsApiService } from '../API/bs-api.service';
import { SimpleGlobalModalService } from '../GlobalModal/simple-global-modal.service';

export interface FeatureFlags {
  flags: { [key: string]: string | null };
  expirationTS: string;
}

export interface FeatureFlagPayload {
  payload: FeatureFlags;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private _bsApi: BsApiService = inject(BsApiService);
  private _globalModalSvc: SimpleGlobalModalService = inject(SimpleGlobalModalService);
  private _featureFlags = new BehaviorSubject<FeatureFlags | null>(null);
  private _featureFlagsReady = new BehaviorSubject<boolean>(false);
  public readonly featureFlagsReady: Observable<boolean> = this._featureFlagsReady.asObservable();
  private readonly CACHE_KEY = 'featureFlags';
  private readonly CHECK_INTERVAL = 10000; // 10 seconds
  private _isInitialized: boolean;

  constructor() {}

  disableClient(): void {
    this._featureFlagsReady.next(false);
    this._featureFlags.next(null);
    localStorage.removeItem(this.CACHE_KEY);
  }

  initializeFeatureFlag(user: UserAttributesInterface): void {
    if (!user.guid || this._isInitialized) {
      return;
    }
    this._isInitialized = true;
    this._fetchFeatureFlags().pipe(
      tap(() => this._featureFlagsReady.next(true)),
      switchMap(() => interval(this.CHECK_INTERVAL).pipe(
        distinctUntilChanged(),
        filter(() => this._shouldFetchFlags()),
        switchMap(() => this._fetchFeatureFlags())
      ))
    ).subscribe();
  }

  private _fetchFeatureFlags(): Observable<FeatureFlags | null> {
    return this._bsApi.get<FeatureFlagPayload>('api-mobile', '/flag').pipe(
      map(response => response.payload),
      tap(flags => {
        this._featureFlags.next(flags);
        localStorage.setItem(this.CACHE_KEY, JSON.stringify(flags));
      }),
      catchError((error): Observable<FeatureFlags | null> => {
        console.error('Feature flags fetch failed', error);
        const cachedFlags = this._getCachedFlags();
        if (cachedFlags) {
          this._featureFlags.next(cachedFlags);
          return of(cachedFlags);
        } else {
          this._globalModalSvc.createGenericErrorModal('feature_flag_error', ()=>{
            this._fetchFeatureFlags();
            this._globalModalSvc.clearModal();
          });
          return of(null);
        }
      })
    );
  }

  private _shouldFetchFlags(): boolean {
    const flags = this._featureFlags.getValue();
    if (!flags) return true;
    const expirationTS = Date.parse(flags.expirationTS);
    return Date.now() >= expirationTS;
  }

  private _getCachedFlags(): FeatureFlags | null {
    const cachedData = localStorage.getItem(this.CACHE_KEY);
    return cachedData ? JSON.parse(cachedData) : null;
  }

  getFlag<T>(flag: string, defaultVal?: T): Observable<T> {
    return this.featureFlagsReady.pipe(
      filter(ready => ready),
      take(1),
      map(() => {
        const flags = this._featureFlags.getValue();
        return (flags?.flags[flag] ?? defaultVal) as T;
      })
    );
  }

  checkMultipleFlags(flags: string[]): Observable<boolean> {
    return this.featureFlagsReady.pipe(
      filter(ready => ready),
      take(1),
      map(() => {
        const flagValues = flags.map(flag => this._featureFlags.getValue()?.flags[flag] ?? false);
        return !flagValues.includes(false);
      })
    );
  }
}
